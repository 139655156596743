<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormCheckbox } from 'bootstrap-vue'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'
import 'vue2-datepicker/index.css'
import Crown from '@/assets/images/icons/Crown.vue'

export default {
    components: {
        BCard,
        BButton,
        BModal,
        DatePicker,
        Crown,
        BFormCheckbox
    },
    data() {
        return {
            pageArrayStock: [], // for Frontend Search
            pageArray: [],

            trackArray: [],

            editModal: false,
            editObject: {
                tRaceId: '',
                tRaceTime: '',
                tRaceTrackId: '',
            },

            nextTourObject: {
                childs: []
            },

            finishModal: false,
            finishObject: [],

            usersModal: false,
            usersObject: {},

            editTransferDate: '',
            datePicker: '',
            datePickerShow: '',

            filterTrackname: '',
            filterTrackbet: '',
            filterTracklap: '',
            filterTracklimit: '',
        }
    },
    mounted() {
        this.mountedData()
    },
    methods: {
        nextTour(item) {
            this.nextTourObject.childs.push({ tpId: item.tpId })
        },
        async handlePicker(value) {
            this.editObject.tRaceTime = await this.$moment(new Date(value)).format('YYYY-MM-DD')
            this.datePickerShow = new Date(`${this.$moment.utc(value).format('YYYY-MM-DD')}T23:59:00.000Z`)
        },
        async mountedData() {
            await this.$http.get(`/tournament/admin/detail/${this.$route.query.tournamentId}`)
                .then(res => {
                    this.pageArrayStock = this.pageArray = res.data
                }).catch(err => {
                    alert('ERROR! PLEASE CONTACT TO DEVELOPER')
                })
            // Get Track List
            await this.$http.get('/features/tracks/list')
                .then(response => {
                    this.trackArray = response.data
                }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
        },
        async tableInteractive(item, index, type) {
            if (type === 'users') {
                this.usersObject = structuredClone(item)
                this.usersModal = true
            } else if (type === 'edit') {
                this.editObject = {
                    tRaceId: item.tRaceId,
                    tRaceTime: item.tRaceTime,
                    tRaceTrackId: item.tRaceTrackId,
                }
                this.editModal = true
            } else if (type === 'finish') {
                this.nextTourObject['tRaceId'] = item.tRaceId
                this.getFinish(item.tRaceId)
            }
        },
        async getFinish(id) {
            await this.$http.get(`/tournament/admin/finish/${id}`)
                .then(res => {
                    this.finishModal = true
                    this.finishObject = structuredClone(res.data)
                }).catch(error => {
                    let errorMessage = ''
                    switch (error.response.data) {
                        case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
                    }
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMessage,
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        async nextStep(id) {
            await this.$http({
                method: 'GET',
                url: `/tournament/admin/tour/create/${this.$route.query.tournamentId}`,
            })
                .then(res => {
                    this.mountedData()
                }).catch(error => {
                    let errorMessage = ''
                    // eslint-disable-next-line default-case
                    switch (error.response.data) {
                        case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
                    }
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMessage,
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        async saveData(type) {
            if (type === 'update') {
                await this.$http({
                    method: 'POST',
                    url: '/tournament/admin/race/update',
                    data: this.editObject,
                })
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Success, Please Wait..',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        this.editModal = false
                        this.mountedData()
                    }).catch(error => {
                        let errorMessage = ''
                        // eslint-disable-next-line default-case
                        switch (error.response.data) {
                            case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
                        }
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMessage,
                                icon: 'XIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
            if (type === 'updateTour') {
                await this.$http({
                    method: 'POST',
                    url: '/tournament/admin/tour/update',
                    data: this.nextTourObject,
                })
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Success, Please Wait..',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        this.nextTourObject = {
                            childs: []
                        }
                        this.finishModal = false
                        this.mountedData()
                    }).catch(error => {
                        let errorMessage = ''
                        // eslint-disable-next-line default-case
                        switch (error.response.data) {
                            case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
                        }
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMessage,
                                icon: 'XIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
    },
}
</script>
    
<template>
    <section class="pb-2">
        <div class="tb-card tb-flex-vertical d-flex justify-content-between">
            <a class="tb-page-title">Race List</a>
            <div>
                <button class="tb-button bg-success ml-auto mr-2" @click="nextStep()" :disabled="pageArray.some(a => a.isFinal == true)">
                    <a class="text-white">Next Step</a>
                </button>
            </div>
        </div>
        <div class="tb-card mt-2">
            <table class="tb-table">
                <thead>
                    <tr class="bg-white">
                        <th style="width: 5%">
                            <input type="text" class="form-control" placeholder="#" disabled readonly>
                        </th>
                        <th style="width: 20%"><input v-model="filterTrackname" type="text" class="form-control"
                                placeholder="Track Name"></th>
                        <th style="width: 20%"><input v-model="filterTrackbet" type="text" class="form-control"
                                placeholder="Track Bet"></th>
                        <th style="width: 20%"><input v-model="filterTracklap" type="text" class="form-control"
                                placeholder="Track Lap"></th>
                        <th style="width: 20%"><input v-model="filterTracklimit" type="text" class="form-control"
                                placeholder="Track Limit"></th>
                        <th style="width: 15%">
                            <input type="text" class="form-control" placeholder="Options" disabled readonly>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in pageArray" :class="{'bg-danger-300' : item.isFinal ? true : false}">
                        <td><a>{{ index + 1 }}</a></td>
                        <td><a>{{ item.tRaceId }}</a></td>
                        <td><a>{{ item.tRaceLimit }}</a></td>
                        <td><a>{{ item.tRaceTime }}</a></td>
                        <td><a>{{ item.tRaceTrackId }}</a></td>
                        <td class="d-flex justify-content-around">
                            <button v-if="item.tRaceStatus == 3" class="tb-button bg-primary"
                                @click="tableInteractive(item, index, 'finish')">
                                <span class="text-white">Finish</span>
                            </button>
                            <button v-if="item.tRaceStatus != 3 && item.tRaceStatus != 6" class="tb-button bg-primary"
                                @click="tableInteractive(item, index, 'edit')">
                                <span class="text-white">Edit</span>
                            </button>
                            <button v-if="item.tRaceStatus != 3" class="tb-button bg-danger"
                                @click="$bvModal.show('bv-modal-tracks'), tableInteractive(item.users, index, 'users')">
                                <span class="text-white">Users</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Edit Modal -->
        <b-modal v-model="editModal" hide-header hide-footer centered>
            <div class="row py-1">
                <div class="col-12 text-center">
                    <a class="tb-page-title">Update track</a>
                    <hr>
                </div>
                <div class="col-12">
                    <label>Race Time</label>
                    <br>
                    <date-picker v-model="datePickerShow" @change="handlePicker" class="ua-h-40" style="width: 100%"
                        format="DD-MM-YYYY" />
                </div>
                <div class="col-12 mt-1">
                    <label>Track Race ID</label>
                    <br>
                    <select v-model="editObject.tRaceTrackId" class="form-control" name="" id="">
                        <option :value="item.trackId" v-for="(item, index) in trackArray" :key="index">
                            {{item.trackName}}
                        </option>
                    </select>
                </div>
                <div class="col-12 text-center">
                    <hr>
                    <b-button variant="outline-primary" class="mt-1" @click="saveData('update')">Update & Close
                    </b-button>
                </div>
            </div>
        </b-modal>

        <!-- Users Detail -->
        <b-modal v-model="usersModal" size="lg" hide-header hide-footer centered>
            <div class="row" style="position:relative; overflow-y:auto; height:100%">
                <div class="col-6 p-1" v-for="(item,index) in usersObject" :key="index">
                    <div class=" tb-border-color-light p-1 rounded-lg h-100">
                        <table style="width:100%">
                            <tr>
                                <th>Name:</th>
                                <td>{{ item.userUsername }} {{ item.userLastname }}
                                </td>
                            </tr>
                            <tr>
                                <th>User Name:</th>
                                <td>{{ item.userUsername }}
                                </td>
                            </tr>
                            <tr>
                                <th>Wallet ID:</th>
                                <td>{{ item.userWalletId }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Finish Detail -->
        <b-modal v-model="finishModal" size="lg" hide-header hide-footer centered>
            <div class="row" style="position:relative; overflow-y:auto; height:100%">
                <div class="col-12 p-1" v-for="(item,index) in finishObject" :key="index">
                    <div class="row tb-border-color-light p-1 rounded-lg">
                        <div class="col-1 tb-page-title d-flex align-items-center justify-content-center">
                            {{item.tpRaceIndex === 0 ? 1 : item.tpRaceIndex ? item.tpRaceIndex + 1 : 'X'}}
                        </div>
                        <div class="col-10">
                            <table style="width: 100%">
                                <tr>
                                    <th>Name:</th>
                                    <td>{{ item.userUsername }} {{ item.userLastname }}
                                    </td>
                                    <th>User Name:</th>
                                    <td>{{ item.userUsername }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-1 tb-page-title d-flex align-items-center justify-content-center">
                            <b-form-checkbox :disabled="item.tpRaceIndex === null" @change="nextTour(item)" switch
                                size="md"></b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <hr>
                    <b-button variant="outline-primary" class="mt-1" @click="saveData('updateTour')">Update & Close
                    </b-button>
                </div>
            </div>
        </b-modal>

    </section>
</template>
    
<style scoped>
th,
td {
    padding: 10px 20px;
}

tr:nth-child(odd) {
    background-color: rgb(243, 243, 243);
}

tr:nth-child(even) {
    background-color: rgb(228, 228, 228);
}

.bg-danger-300 {
    background-color: #ec7e7e !important;
}
</style>
    